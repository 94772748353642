import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/select-audit',
    name: 'selectAudit',
    component: () => import(/* webpackChunkName: "audit" */ '@/views/audit/select-audit.vue'),
    meta: {
      title: '新建审批'
    }
  },
  {
    path: '/audit-details',
    name: 'auditDetails',
    component: () => import(/* webpackChunkName: "audit" */ '@/views/audit/audit-details.vue'),
    meta: {
      title: '审批详情'
    }
  },
  {
    path: '/create-audit',
    name: 'createAudit',
    component: () => import(/* webpackChunkName: "audit" */ '@/views/audit/create-audit.vue'),
    meta: {
      title: '新增审批'
    }
  }
]

export default routes