import './utils/rem'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'vant/lib/index.css'
import './utils/interaction'
import { Button, Form, Field, Icon, DatePicker, Popup, Checkbox, CheckboxGroup, Collapse, CollapseItem, Uploader,
  Picker, PickerGroup, Calendar, Area, TimePicker, RadioGroup, Radio, Cell, CellGroup, Toast, Tab, Tabs, Divider,
  ImagePreview, Tag } from 'vant'

const app = createApp(App)

const appUseList = [
  store,
  router,
  Button,
  Form,
  Field,
  Icon,
  DatePicker,
  Popup,
  Checkbox,
  CheckboxGroup,
  Collapse,
  CollapseItem,
  Uploader,
  Picker,
  PickerGroup,
  Calendar,
  Area,
  TimePicker,
  RadioGroup,
  Radio,
  Cell,
  CellGroup,
  Toast,
  Tab,
  Tabs,
  Divider,
  Tag,
  ImagePreview
]

appUseList.forEach(component => {
  app.use(component)
})

app.mount('#app')
