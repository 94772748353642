interface storageProps{
	[key: string]: string; // 添加索引签名
}
export const LOCAL_STORAGE:storageProps = {
	token:'USER_TOKEN',
	company:'COMPANY_INFO',
	user:'USER_INFO'
  };

  const __NEXT_NAME__ = 'STARAUXO'


/**
 * window.localStorage 浏览器永久缓存
 * @method set 设置永久缓存
 * @method get 获取永久缓存
 * @method remove 移除永久缓存
 * @method clear 移除全部永久缓存
 */
export const local = {
	setKey(key: string) {
		return `${__NEXT_NAME__}:${key}`;
	},
	// 设置永久缓存
	set<T>(key: string, val: T) {
		const res = Object.keys(LOCAL_STORAGE).find(v=>LOCAL_STORAGE[v]===key)
		if(!res){
			const error = new Error('不允许设置已定义key值以外storage，请先配置到LOCAL_STORAGE')
			console.error(error)
			return
		}
		window.sessionStorage.setItem(local.setKey(key), JSON.stringify(val));
	},
	// 获取永久缓存
	get(key: string) {
		const json = <string>window.sessionStorage.getItem(local.setKey(key));
		return JSON.parse(json);
	},
	// 移除永久缓存
	remove(key: string) {
		window.sessionStorage.removeItem(local.setKey(key));
	},
	// 移除全部永久缓存
	clear() {
		window.sessionStorage.clear();
	},
};
