<template>
  <div class="page-home">
    <ul>
      <li v-for="(item, index) in routerList" :key="index" @click="goPage(item)">{{ item.name }}</li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
// import { Vue } from 'vue-class-component';
import { List } from '@/typings/home'
import router from '@/router';

const routerList: List[] = [
    {
      name: '创建工单',
      path: 'createOrder'
    },
    {
      name: '预览工单',
      path: 'previewOrder'
    },
    {
      name: '工单详情',
      path: 'orderDetails'
    },
    {
      name: '填写作业结果',
      path: 'worker'
    },
    {
      name: '填写质检结果',
      path: 'inspector'
    },
    {
      name: '填写并检查作业结果',
      path: 'leader'
    },
    {
      name: '新建审批',
      path: 'selectAudit',
    },
    {
      name: '审批详情',
      path: 'auditDetails',
    },
    {
      name: '新增审批',
      path: 'createAudit',
    },
    {
      name: '创建任务',
      path: 'createTask'
    },
    {
      name: '任务详情',
      path: 'taskDetails'
    }
  ]
  const goPage = (item: List) => {
    router.push({ name: item.path })
  }

</script>

<style lang="scss">
  .page-home {
    li {
      font-size: 14px;
      text-align: left;
      border-bottom: 1px solid #dddee3;
      padding: 10px;
      font-weight: 500;
    }
  }
</style>
