<template>
  <div class="page-app">
    <router-view/>
  </div>
</template>

<style lang="scss">
@import "./style/index.scss";
.page-app {
  margin-bottom: constant(safe-area-inset-bottom);
  margin-bottom: env(safe-area-inset-bottom);
}
</style>
