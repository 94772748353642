const baseSize = 75 //跟postcss.config.js中rootValue的值是一致的
// 设置 rem 函数
const setRem = () => {
	const scale = document.documentElement.clientWidth / 750
	document.documentElement.style.fontSize = (baseSize * Math.min(scale, 2)) + 'px'
}
// 初始化
setRem()
// 改变窗口大小时重新设置 rem
window.onresize = function () {
    setRem()
}
