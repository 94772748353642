import { Router } from 'vue-router';

/**
 * 页面跳转
 * @param router
 * @param path
 */
export const goToPage = async (router: Router, path: string) => {
  await router.push({ path });
};

/**
 * 获取路由参数
 * @param path
 */
export const routerParams = (path: string) => {
  const pathParams = path.split('?');
  const query = {};
  if (pathParams[1]?.length > 0) {
    const params = pathParams[1].split('&');
    params.forEach((value) => {
      const val = value.split('=');
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      query[val[0]] = val[1];
    });
  }
  return {
    path: pathParams[0],
    query
  };
};

/**
 * 防抖
 * @param func
 * @param delay
 * @return {debounced}
 */
export const debounce = (func: any, delay = 1): any => {
  let timeoutId: any;
  return (...params: any) => {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, params);
    }, delay * 1000);
  };
};

/**
 * 节流
 * @param func
 * @param interval
 * @return {throttled}
 */
export const throttle = (func: any, interval = 1) => {
  let timeoutId: any = null;
  let preExecuteAt = Date.now();

  const handle = (...params: any) => {
    func.apply(this, params);
    preExecuteAt = Date.now();
  };

  return (...params: any) => {
    clearTimeout(timeoutId);
    const waited = Date.now() - preExecuteAt;
    if (waited >= interval * 1000) {
      handle(...params);
    } else {
      timeoutId = setTimeout(() => {
        handle(...params);
      }, interval * 1000 - waited);
    }
  };
};

export const deepClone = (target: any, cache = new Map()) => {
  if (!target || typeof target !== 'object') {
    return target
  }
  // 防止循环引用，程序进入死循环
  if (cache.get(target)) return cache.get(target)
  // 找到所属原型上的constructor，所属原型上的constructor指向当前对象的构造函数
  const cloneObj = new target.constructor()
  // 缓存拷贝的对象，用于处理循环引用的情况
  cache.set(target, cloneObj)
  for (const key in target) {
    if (Object.prototype.hasOwnProperty.call(target, key)) {
      cloneObj[key] = deepClone(target[key], cache) // 递归拷贝
    }
  }
  return cloneObj
}

export const getParameterFromUrl = (parameterName: string) => { // 从url中获取需要的参数
  const currentUrl = window.location.href;
  // 使用 URL 对象解析 URL
  const url = new URL(currentUrl);
  // 获取查询字符串参数 token 的值
  return url.searchParams.get(parameterName);
}
